import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import "../css/style.css";
import placeholder from "../images/placeholder.png";
import { Helmet } from "react-helmet";
import Layout from "../components/layout";


const EventList = ({ data,pageContext, path }) => {
  const [pastEvents, setPastEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);

  const post = data.allWordpressPage.edges[0].node;

  const metatitle = post.acf.meta_title ;
  const metadescription = post.acf.meta_description; 
const focus_keyphrase = post.acf.focus_keyphrase; 
const canonical_url = `https://theworldgrad.com${path}`; 
console.log("Events and Webinar ->",canonical_url);
  useEffect(() => {
    const events = data.allWordpressWpEvents.nodes;


    const now = new Date();

    const past = [];
    const future = [];

    events.forEach((event) => {
      const endDate = event.acf.event_end_date || event.acf.event_date;
      const eventDate = new Date(endDate);

      if (eventDate < now) {
        past.push(event);
      } else {
        future.push(event);
      }
    });

    // Sort future events in ascending order (earliest first)
    const sortedFuture = future.sort((a, b) => new Date(a.acf.event_date) - new Date(b.acf.event_date));

    // Sort past events in descending order (latest first)
    const sortedPast = past.sort((a, b) => new Date(b.acf.event_date) - new Date(a.acf.event_date)).slice(0, 3); // Limit to 3 events

    setFutureEvents(sortedFuture);
    setPastEvents(sortedPast);
  }, [data]);

  // Helper function for date formatting and suffix
  const formatDate = (date) => ({
    day: ("0" + date.getDate()).slice(-2),
    month: date.toLocaleString([], { month: "short" }),
    nth: (() => {
      const d = date.getDate();
      if (d > 3 && d < 21) return "th";
      switch (d % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    })()
  });



  const renderEventItem = (event) => {
    const eventStartDate = new Date(event.acf.event_date);
    const eventEndDate = new Date(event.acf.event_end_date || event.acf.event_date);

    if (isNaN(eventStartDate.getTime()) || isNaN(eventEndDate.getTime())) {
      console.error('Invalid date:', event.slug);
      return null;
    }

    const startFormatted = formatDate(eventStartDate);
    const endFormatted = formatDate(eventEndDate);

    const eventDateClass = `event-date ${event.acf.event_end_date ? 'event-end-date' : ''}`;
    
    return (
      <div className="col-lg-4 col-md-6 pb-5" key={event.slug}>
        <a href={`/event-detail/${event.slug}/`} className="d-flex h-100">
          <div className="event-sec">
            <div className="event-img-wrap">
              <img
                src={event.featured_media?.source_url || placeholder}
                alt={event.title}
              />
              <p className={eventDateClass}>
                <span>
                  {startFormatted.day}
                  <span className="nth-lowercase">{startFormatted.nth}</span> {startFormatted.month}
                  {event.acf.event_end_date && (
                    <>
                      {" "} - {endFormatted.day}
                      <span className="nth-lowercase">{endFormatted.nth}</span> {endFormatted.month}
                    </>
                  )}
                </span>
              </p>
            </div>
            <div className="event-content-wrap p-3">
              <h4
                className="event-title"
                dangerouslySetInnerHTML={{ __html: event.title }}
              ></h4>
            </div>
          </div>
        </a>
      </div>
    );
  };

  return (
<Layout>
				<Helmet>
				<title>{metatitle}</title>
          <meta name="description" content={metadescription} />
		
          {(() => {if(post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) {return (<meta name="keywords" content={focus_keyphrase} /> )} })()}
          <link rel="canonical" href={canonical_url} /> 
           {(() => {if(post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) {return (<script type="application/ld+json">{JSON.stringify (JSON.parse(post.acf.dynamic_schema))}</script> )} })()}  
          

				</Helmet>
 {/*canonical_url*/}
				<div class="newStyle">  
		 <div className="container">
		 <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
											<li class="breadcrumb-item">
												<a href="/">Home</a>
											</li>
											<li class="breadcrumb-item active"> Upcoming Events</li>
										</ol>
				</div>
				</div>
	<section className="event-listing-sec pt-5">
	<div className="container">
	  <h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items}</h1>
  
	  {/* Future Events */}
	  <div className="row">
	  {futureEvents.length === 0 ? (
           <div className="col-12 position-relative">
		   <p className="text-center">No upcoming events found. Stay tuned for exciting updates!<br/><br/><br/><br/></p>
		 </div>
        ) : (
          futureEvents.map(renderEventItem)
        )}
	  </div>
  
	  <h1 className="event-main-title text-center mb-5 h2">{post.acf.label_of_list_of_event_items_past}</h1>
  
	  {/* Past Events */}
	  <div className="row">
	  {pastEvents.length === 0 ? (
        <div className="col-12 position-relative">
		<p className="text-center">No past events available.<br/><br/><br/><br/></p>
	  </div>
        ) : (
          pastEvents.map(renderEventItem)
        )}
	  </div>
	</div>
	   {/* Future Events */}
	 

  </section>
  </Layout>
  );
};

export default EventList;

export const query = graphql`
  query EventQuery {
  allWordpressPage(filter: { wordpress_id: { in: 2499 } }) {
			edges {
				node {
					id
					title
					featured_media {
						source_url
					}
					acf {
						register_link {
							title
							url
							target
						}
						meta_title
						meta_description
						focus_keyphrase
						canonical_url
						dynamic_schema
						banner_label
						label_of_list_of_event_items
						label_of_list_of_event_items_past
						webinar_start_date
						webinar_start_on
					}
				}
			}
		}
    allWordpressWpEvents {
      nodes {
        title
        slug
        acf {
          event_date
          event_end_date
        }
        featured_media {
          source_url
        }
      }
    }
  }
`;
